:root{
    --primary-head-height: 46px;
    --dark-bg-color: #475669;
    --sidebar-bg-color: #fff;
    --sub-service-width: 230px;
}

#pc-web-header{
    height: var(--primary-head-height);
    background-color: var(--dark-bg-color);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 6px 12px;
}
#pc-web-header-left{display: flex; justify-content: flex-start; align-items: center; list-style: none; padding: 0; margin: 0;}
#pc-web-header-left svg{font-size: 20px; color: white; margin-right: 8px;}
#pc-web-header-left li:after{content: " | "; margin: 0 8px; color: white;}
#pc-web-header-left li:last-child:after{content:""}

#sidebar{
    position: fixed;
    height: calc(100vh - var(--primary-head-height));
    top: var(--primary-head-height);
    left: 0;
    bottom: 0;
    overflow-y: auto;
    border-right: 1px solid #f0f0f0;
    background-color: var(--sidebar-bg-color);
}
@keyframes webUIAppFadeIn {
    from {
        opacity: 0;
        width: 100vw;
        height: 0;
    }
    to {
        opacity: 1;
        width: 100vw;
        height: 100vh;
    }
}


@keyframes rotate  {
    0% {
        transform: rotate(0deg);
    }
    25% {
        transform: rotate(90deg);
    }
    50% {
        transform: rotate(180deg);
    }
    75% {
        transform: rotate(270deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

#sidebar .ant-menu.ant-menu-inline-collapsed{width: inherit;}
ul[id="*fullmenu-popup"]{background-color: red;}
#sub-service{background-color: white; height: calc(100% - var(--primary-head-height)); overflow-y: auto;}
#sub-service h2{padding: 12px 8px 0 22px;}
#sub-app-view{padding: 12px; height: calc(100% - var(--primary-head-height)); overflow-y: auto;}
#webapp-body{max-height: calc(100vh - var(--primary-head-height)); overflow-y: auto;}
#webapp-body .ant-page-header{padding: 6px 0;}
#root{animation: webUIAppFadeIn 3s ease-in forwards;}

ul[id$="all-full-menu-popup"]{width: 40vw!important; display: flex; flex-direction: column; flex-basis: 25%; flex-wrap: wrap; border: 2px solid rgba(255,255,255,.9)}
#toolbar{position: sticky; top: 0; z-index: 202410; filter: alpha(opacity=100);}
#hot-top-toolbar{position: sticky; top: 0; z-index: 202410; filter: alpha(opacity=100);}
#webapp-body .ant-page-header + .ant-card{position: sticky; top: 0; z-index: 202410; filter: alpha(opacity=100)}
#pc-web-header .ant-menu-submenu-title{color: white;}


